import React, { FC } from 'react';

const TrilemmaLogo: FC<Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>> = (props) => {
  return (
    <img
      {...props}
      src={`${process.env.PUBLIC_URL}/assets/images/logo-light.png`}
      alt='trilemma-logo'
    />
  );
};

export default TrilemmaLogo;
