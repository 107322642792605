import React, { FC } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import TrilemmaLogo from '../Illustrations/TrilemmaLogo';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, Typography, useScrollTrigger } from '@mui/material';
import useStyles from './styles';
import CloseIcon from '@mui/icons-material/Close';
import Footer from '../../Footer';
import { useNavigate } from 'react-router';
import { routes } from '../../../router';
import CheckIcon from '@mui/icons-material/Check';

interface NavItem {
  name: string;
  link: string;
  isUrl?: boolean;
}

interface Props {
  window?: () => Window;
  navItems?: NavItem[];
  homeLink?: string;
  position?: 'fixed' | 'absolute' | 'relative' | 'static' | 'sticky';
}

const drawerWidth = '80%';

const ImprovedAppBar: FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslation();
  const { position, window: windowProp } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });
  const { classes } = useStyles();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const isMenuItemActive = (menuItem: NavItem) => {
    return location.pathname.startsWith(`/${menuItem.link}`);
  };

  const changeLanguage = (event: any) => {
    const selectedLanguage = event.target ? event.target.value : event as string;
    i18n.changeLanguage(selectedLanguage).then();
  };

  const drawer = (
    <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ py: 1, mr: 3, display: 'flex', justifyContent: 'end' }}>
        <IconButton color='inherit' aria-label='close' edge='end' onClick={handleDrawerToggle}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        {props.navItems?.map((item) => (
          <ListItem key={t(item.name)} disablePadding>
            <ListItemButton
              sx={{ textAlign: 'center' }}
              className={isMenuItemActive(item) ? classes.activeButton : ''}
              href={item.link.includes('http') ? `${item.link}` : `${location.origin}/${item.link}`}
              target={item.link.includes('http') ? '_blank' : ''}
              onClick={handleDrawerToggle}
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography variant='h6' color='primary'>
                    {t(item.name)}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Button
        variant='text'
        color='primary'
        className={i18n.language == 'en' ? classes.activeButton : classes.linkButton}
        sx={{ fontWeight: 'bold' }}
        onClick={() => changeLanguage('en')}
      >
        {t('En')}
        {/*{t('En')} {i18n.language === 'en' && <CheckIcon />}*/}
      </Button>
            <Button
            variant='text'
            color='primary'
            className={i18n.language == 'fr' ? classes.activeButton : classes.linkButton}
            sx={{ fontWeight: 'bold'}}
            onClick={() => changeLanguage('fr')}
          >
            {t('Fr')}
            {/*{t('Fr')}  {i18n.language === 'fr' && <CheckIcon />}*/}
          </Button>
      <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'center', flex: 1 }}>
        <Footer />
      </Box>
    </Box>
  );

  const container = windowProp !== undefined ? () => windowProp().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CssBaseline />
      <AppBar
        component='nav'
        elevation={0}
        sx={{ backgroundColor: 'transparent', py: 1, alignItems: 'center' }}
        className={`${trigger === false ? '' : classes.appBarScrolled}`}
        position={position ?? 'fixed'}
      >
        <Toolbar sx={{ maxWidth: '1600px', width: '100%' }}>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Button
              onClick={() => navigate(props.homeLink ?? '/')}
              sx={{
                background: 'transparent',
                '&:hover': { background: 'transparent' },
              }}
            >
              <TrilemmaLogo style={{ maxWidth: '160px' }} />
            </Button>
          </Box>
          <IconButton
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {props.navItems?.map((item) => (
              <Button
                key={t(item.name)}
                sx={{
                  color: '#000',
                  fontFamily: 'TrilemmaSemibold',
                  fontSize: 18,
                  mr: 1,
                }}
                className={isMenuItemActive(item) ? classes.activeButton : ''}
                // href={
                //   item.link.includes('http') ? `${item.link}` : `${location.origin}/${item.link}`
                // }
                href=''
                onClick={() => navigate(`${item.link}`)}
                target={item.link.includes('http') ? '_blank' : ''}
              >
                {t(item.name)}
              </Button>
            ))}
            <Select
              sx={{
                color: '#000',
                fontFamily: 'TrilemmaSemibold',
                fontSize: 18,
                mr: 1,
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
              }}
              onChange={changeLanguage}
              value={i18n.language}
              inputProps={{ 'aria-label': 'Select language' }}
            >
              <MenuItem key={'en'}  value={'en'}>{t('En')}</MenuItem >
              <MenuItem key={'fr'} value={'fr'}>{t('Fr')}</MenuItem >
            </Select>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component='nav'>
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {position === 'fixed' && (
        <Box component='main' sx={{ py: { xs: 1, lg: 3 } }}>
          <Toolbar />
        </Box>
      )}
    </Box>
  );
};

export default ImprovedAppBar;
