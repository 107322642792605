import { makeStyles, Theme } from '../../styles';

const useStyles = makeStyles()((theme: Theme) => ({
  layoutContainer: {
    minHeight: 'calc(100vh - 230px)',
    backgroundColor: theme.trilemma.palette.background,
  },
}));

export default useStyles;
