import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

/*
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    debug: process.env.NODE_ENV === 'development',
  });*/


async function fetchTranslationResources() {
  try {
    const enResponse = await fetch('/locales/en/translation.json');
    const frResponse = await fetch('/locales/fr/translation.json');

    if (enResponse.ok && frResponse.ok) {
      const enTranslation = await enResponse.json();
      const frTranslation = await frResponse.json();

      return {
        en: { translation: enTranslation },
        fr: { translation: frTranslation },
      };
    }
    throw new Error('Failed to fetch translation resources');
  } catch (error) {
    console.error('Error fetching translation resources:', error);
    return null;
  }
}

(async () => {
  const resources = await fetchTranslationResources();
  if (resources) {
    await i18next
      .use(initReactI18next)
      .init({
        resources: resources,
        lng: 'en', // Set the default language
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false,
        },
        debug: process.env.NODE_ENV === 'development',
      });
  }
})();
export default i18next;
