import React, { FC } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Box, Grid } from '@mui/material';
import useStyles from './styles';
import { Outlet } from 'react-router';

const Layout: FC = () => {
  const { classes } = useStyles();
  const isSharedPage = () => {
    return location.pathname.startsWith('/shared') || location.pathname.startsWith('/app');
  };

  return (

    <Grid
      container
      spacing={0}
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={10} sx={{maxWidth: {xl: '1536px !important'}}}>
        
        {!isSharedPage() && <Header />}
        <Box className={classes.layoutContainer}>
          <Outlet />
        </Box>
        {!isSharedPage() && (
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Footer />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default Layout;
