const mainRoutes = {
  home: '',
  nft: 'nft',
  aboutUs: 'about-us',
  contactUs: 'contact-us',
  privacyPolicy: 'privacy',
  termsOfConditons: 'terms',
  appRecoveryLink: 'app/:recoveryhash',
};

const nftRoutes = {
  collection: 'collection/:collectionhash',
  collections: '',
  nftDetails: {
    main: ':blockchain/:collectionhash/:tokenid',
    transfer: ':blockchain/:collectionhash/:tokenid/transfer',
  },
};

const sharedRoutes = {
  aboutUs: 'shared/about-us',
  privacyPolicy: 'shared/privacy',
  termsOfConditons: 'shared/terms',
};

const routes = {
  root: '/',
  mainRoutes,
  nftRoutes,
  sharedRoutes,
};

export default routes;
