import React, { FC } from 'react';
import ImprovedAppBar from '../common/ImprovedAppBar';
import { routes } from '../../router';
import { STORE_LINK } from '../../constants/link';

const Header: FC = () => {
  const appBarProps = {
    navItems: [
      {
        name: 'Header.Menu.Collections',
        link: routes.mainRoutes.nft,
      },
      {
        name: 'Header.Menu.AboutUs',
        link: routes.mainRoutes.aboutUs,
      },
      // {
      //   name: 'Header.Menu.Shop',
      //   link: STORE_LINK,
      //   isUrl: true,
      // },
    ],
    homeLink: routes.root,
  };

  return (
    <ImprovedAppBar
      navItems={appBarProps.navItems}
      homeLink={appBarProps.homeLink}
      // position={
      //   location.pathname === routes.mainRoutes.home || location.pathname === '/'
      //     ? 'static'
      //     : 'fixed'
      // }
      position='fixed'
    />
  );
};

export default Header;
