import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: location.protocol + '//' + location.host + '/' + process.env.REACT_APP_BASE_URL,
});

const axiosIpfsInstance = axios.create({
  baseURL: process.env.REACT_APP_IPFS_BASE_URL,
});

const axiosBlockchainScanInstance = axios.create({
  baseURL: process.env.REACT_APP_BLOCKCHAIN_SCAN_BASE_URL,
});

export { axiosInstance, axiosIpfsInstance, axiosBlockchainScanInstance };
