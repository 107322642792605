import { Box, IconButton } from '@mui/material';
import React, { FC } from 'react';
import CustomLogo from '../common/Illustrations/CustomLogo';

interface SocialNetwork {
  name: string;
  link: string;
}

const Contacts: FC = () => {
  const socialNetworks: SocialNetwork[] = [
    // {
    //   name: 'instagram',
    //   link: 'https://www.instagram.com/oneseedmusic/',
    // },
    // {
    //   name: 'facebook',
    //   link: 'https://fr-fr.facebook.com/oneseedmusic',
    // },
    {
      name: 'Twitter',
      link: 'https://twitter.com/OneSeedMusic',
    },
  ];
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {socialNetworks.map((social) => (
        <Box key={social.name} sx={{ mr: 2 }}>
          <IconButton
            color='primary'
            href={social.link}
            target='_blank'
            sx={{ minWidth: '40px' }}
          >
            <CustomLogo name={social.name} />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};

export default Contacts;
