export enum IconType {
  CERTIFICATE = 'certificate',
  CART = 'cart',
  SECURITY = 'security',
  BELL = 'bell',
  DIAMOND = 'diamond',
  VOTE = 'vote',
  MONEY = 'money',
  CYCLE = 'cycle',
  SUNGLASSES = 'sunglasses',
  AUCTION = 'auction',
  ILLUSTRATION = 'illustration',
}
