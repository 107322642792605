import { makeStyles, Theme } from '../../../styles';

const useStyles = makeStyles()((theme: Theme) => ({
  appBarScrolled: {
    backdropFilter: theme.trilemma.navbar.backdropFilter,
    backgroundColor: theme.trilemma.navbar.backgroundColor,
    transition: theme.trilemma.navbar.transition
  },
  activeButton: {
    backgroundColor: theme.trilemma.palette.menuItemActiveBackground,
  },
  linkButton: {
    color: theme.trilemma.palette.secondaryText,
  },
}));

export default useStyles;
