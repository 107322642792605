import React, { FC } from 'react';
import { ReactSVG } from 'react-svg';

import { SocialNetworkType } from '../../../../shared/models/CreatorDto';
import { IconType } from '../../../../shared/models/Icon';

interface CustomLogoProps {
  name: string;
  className?: string;
  fontSizeSx?: string;
}

const CustomLogo: FC<CustomLogoProps> = (props: CustomLogoProps) => {
  const logos = [
    { name: SocialNetworkType.DISCORD },
    { name: SocialNetworkType.FACEBOOK },
    { name: SocialNetworkType.INSTAGRAM },
    { name: SocialNetworkType.LINKEDIN },
    { name: SocialNetworkType.MAIL },
    { name: SocialNetworkType.PINTEREST },
    { name: SocialNetworkType.SNAPCHAT },
    { name: SocialNetworkType.TIKTOK },
    { name: SocialNetworkType.TWITTER },
    { name: SocialNetworkType.YOUTUBE },
    { name: IconType.CERTIFICATE },
    { name: IconType.CART },
    { name: IconType.SECURITY },
    { name: IconType.BELL },
    { name: IconType.DIAMOND },
    { name: IconType.VOTE },
    { name: IconType.MONEY },
    { name: IconType.CYCLE },
    { name: IconType.SUNGLASSES },
    { name: IconType.AUCTION },
    { name: IconType.ILLUSTRATION },
  ];

  const getLogo = (name: string) => {
    return logos.find((logo) => logo.name.toLowerCase() === name.toLowerCase());
  };

  return (
    <ReactSVG
      src={`${process.env.PUBLIC_URL}/assets/icons/${getLogo(
        props?.name,
      )?.name.toLocaleLowerCase()}.svg`}
      className={props?.className}
      beforeInjection={(svg) => {
        svg.setAttribute('style', `width: ${props?.fontSizeSx}; height: 100%`);
      }}
    />
  );
};

export default CustomLogo;
