import { Box, Button } from '@mui/material';
import React, { FC } from 'react';
import Contacts from '../Contacts';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { routes } from '../../router';
import { useNavigate } from 'react-router';

const Footer: FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        mx: 6,
        py: 4,
        borderTop: 1,
        borderColor: 'rgba(12, 12, 12, 0.1)',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'start' } }}>
        <Contacts />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          flex: 1,
          justifyContent: 'end',
        }}
      >
        <Button
          variant='text'
          color='primary'
          className={classes.linkButton}
          sx={{ fontWeight: 'bold' }}
          onClick={() => navigate(routes.mainRoutes.privacyPolicy)}
        >
          {t('Footer.PrivacyPolicy')}
        </Button>
        <Button
          variant='text'
          color='primary'
          className={classes.linkButton}
          sx={{ fontWeight: 'bold' }}
          onClick={() => navigate(routes.mainRoutes.termsOfConditons)}
        >
          {t('Footer.TermsOfService')}
        </Button>
        <Button
          variant='text'
          color='primary'
          className={classes.linkButton}
          sx={{ fontWeight: 'bold' }}
          onClick={() => navigate(routes.mainRoutes.contactUs)}
        >
          {t('Footer.ContactUs')}
        </Button>
      </Box>
    </Box>
  );
};

export default Footer;
