export class CreatorDto {
  idCollection?: number;
  creatorName?: string;
  image?: string;
  description?: string;
  socialNetwork?: SocialNetworkDto[];
  creationDate?: string;
  modificationDate?: string;
}

export class SocialNetworkDto {
  name?: string;
  link?: string;
}

export enum SocialNetworkType {
  INSTAGRAM = 'Instagram',
  SNAPCHAT = 'Snapchat',
  TIKTOK = 'TikTok',
  DISCORD = 'Discord',
  TWITTER = 'Twitter',
  FACEBOOK = 'Facebook',
  MAIL = 'Mail',
  PINTEREST = 'Pinterest',
  YOUTUBE = 'YouTube',
  LINKEDIN = 'LinkedIn',
}
