import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { FC, Suspense, lazy } from 'react';
import routes from './routes';
import { ScrollToTop } from '../components/common/ScrollToTop/ScrollToTop';

import Layout from '../pages/Layout';

const Home = lazy(() => import('../pages/Home'));
const Nft = lazy(() => import('../pages/Nft'));
const AboutUs = lazy(() => import('../pages/AboutUs'));
const ContactUs = lazy(() => import('../pages/ContactUs'));
const CollectionDetails = lazy(() => import('../pages/Nft/CollectionDetails'));
// const NftDetails = lazy(() => import('../pages/Nft/NftDetails'));
const Collections = lazy(() => import('../pages/Nft/Collections'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('../pages/TermsOfService'));
const PageNotFound = lazy(() => import('../pages/PageNotFound'));
// const NftTransfer = lazy(() => import('../pages/Nft/NftTransfer'));
const NftRecoveryLink = lazy(() => import('../pages/NftRecoveryLink'));


const Router: FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <ScrollToTop />
        <Routes>
          <Route path={'/'} element={<Layout />}>
            <Route path={routes.root} element={<Suspense fallback={<div>Loading...</div>}><Home /></Suspense>} />
            <Route path={routes.mainRoutes.nft} element={<Suspense fallback={<div>Loading...</div>}><Nft /></Suspense>}>
              <Route path={routes.nftRoutes.collections} element={<Collections />} />
              <Route path={routes.nftRoutes.collection} element={<CollectionDetails />} />
              <Route path={routes.nftRoutes.nftDetails.main} element={<CollectionDetails />}></Route>
              {/* <Route path={routes.nftRoutes.nftDetails.main} element={<NftDetails />}></Route> */}
              {/* <Route path={routes.nftRoutes.nftDetails.transfer} element={<NftTransfer />}></Route> */}
            </Route>
            <Route path={routes.mainRoutes.aboutUs} element={<Suspense fallback={<div>Loading...</div>}><AboutUs /></Suspense>} />
            <Route path={routes.mainRoutes.privacyPolicy} element={<Suspense fallback={<div>Loading...</div>}><PrivacyPolicy /></Suspense>} />
            <Route path={routes.mainRoutes.termsOfConditons} element={<Suspense fallback={<div>Loading...</div>}><TermsOfService /></Suspense>} />
            <Route path={routes.mainRoutes.contactUs} element={<Suspense fallback={<div>Loading...</div>}><ContactUs /></Suspense>} />
            <Route path={routes.mainRoutes.appRecoveryLink} element={<Suspense fallback={<div>Loading...</div>}><NftRecoveryLink /></Suspense>} />
            <Route path={routes.sharedRoutes.aboutUs} element={<AboutUs />} />
            <Route path={routes.sharedRoutes.privacyPolicy} element={<PrivacyPolicy />} />
            <Route path={routes.sharedRoutes.termsOfConditons} element={<TermsOfService />} />
            <Route path='*' element={<PageNotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
